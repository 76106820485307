import React, { useContext } from "react";

import AppContext from "@contexts/AppContext.js";

import Sidebar from "@containers/Sidebar";
import Topbar from "@containers/Topbar";
import WorkArea from "@containers/WorkArea";

import "@styles/Layout.scss";

const Layout = ({ children }) => {
  const { stateApp } = useContext(AppContext);
  return (
    <div id="Layout" className="layout">
      <Sidebar />
      <div
        className={stateApp.navToggleActive ? "body-area" : "body-area active"}
      >
        <Topbar />
        <WorkArea>{children}</WorkArea>
      </div>
    </div>
  );
};

export default Layout;
