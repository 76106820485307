import React from "react";
import { Pagination } from "react-bootstrap";
import '@styles/PaginationTable.scss'
const PaginationTable = ({ total, current, onChangePage }) => {
  console.log('total', total)
  let items = [];
  if (current > 1) {
    items.push(
      <Pagination.Prev key="prev" onClick={() => onChangePage(current - 1)} />
    );
  }
  for (let page = current; page < current + 4; page++) {
    items.push(
      <Pagination.Item
        key={page}
        data-page={page}
        active={page === current}
        onClick={() => onChangePage(page)}
      >
        {page}
      </Pagination.Item>
    );
  }
  if (current < total) {
    items.push(
      <Pagination.Next key="next" onClick={() => onChangePage(current + 1)} />
    );
  }
  return <Pagination id="PaginationTable" className="pagination-table">{items}</Pagination>;
};

export default PaginationTable;
