import React, { useContext } from 'react';

import AppContext from '@contexts/AppContext.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "@styles/Topbar.scss";

const Topbar = () => {
    const { stateApp, navToggle } = useContext(AppContext);

    const handleToggleNav = () => {
        navToggle(!stateApp.navToggleActive);
    }

    return (
        <div id='Topbar' className='topbar' >
            <div className='topbar-wrapper'>
                <div className='toggle' onClick={handleToggleNav}>
                    <i><FontAwesomeIcon icon={['fas', 'bars']} /></i>
                </div>
                <div className='search'>
                    <label>
                        <input type="text" name="" id="" placeholder='Search here' />
                        <i><FontAwesomeIcon icon={['fas', 'search']} /></i>
                    </label>
                </div>
                <div className='user'>
                    <img src='https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg'></img>
                </div>
            </div>
        </div>
    );
};

export default Topbar;
