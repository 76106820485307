import { useState } from "react";

const useLoginState = () => {
  const [login, setLogin] = useState({ username: "", password: "" });

  const setLoginForm = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    switch (inputName) {
      case "username":
        setLogin({ ...login, username: inputValue });
        break;
      case "password":
        setLogin({ ...login, password: inputValue });
      default:
        break;
    }
  };
  return { login, setLoginForm };
};

export default useLoginState;
