import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import * as moment from "moment";
import * as Yup from "yup";
import { URL_API, CERTIFICATE_UPLOAD_FILE_NAME } from "@configs/consts";
import {
  Tab,
  Tabs,
  Form as FormBoot,
  FloatingLabel,
  Row,
  Col,
  Table,
  Button,
  Accordion,
} from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaginationTable from "@components/PaginationTable";
import CertificateModalForm from "@components/CertificateModalForm";

import Layout from "@containers/Layout";

import "@styles/Certificate.scss";

const Certificate = () => {
  /*Pagination*/
  const [data, setData] = useState({ list: [], totalPage: 0 });
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${URL_API}/system/certificate/list?size=25&page=${page}`
        );
        const res = response.data;
        console.log("res", res);
        setData({
          list: res.data.list,
          totalPage: res.data.pagination.total_page,
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [page]);
  const handleChangePage = useCallback((page) => {
    console.log("page", page);
    setPage(page);
  }, []);

  /*Modal */
  const [modalShow, setModalShow] = useState(false);
  const [certificate, setCertificate] = useState({});
  const handleClickUpdate = (row) => {
    setCertificate(row);
    setModalShow(true);
  };

  /*File Input */
  const fileUploadTemplate = useRef(null);
  const [messageFileUpload, setMessageFileUpload] = useState("");

  return (
    <Layout>
      <div className="certificate-page">
        <div className="page-title">
          <p>Certificados</p>
        </div>
        <div className="tabs-wrapper">
          <Tabs
            defaultActiveKey="table"
            id="tabs-menu"
            className=""
            justify={false}
          >
            <Tab eventKey="table" title="Lista">
              <Row>
                <Col>
                  <Accordion defaultActiveKey="" className="search-wrapper">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <i>
                          <FontAwesomeIcon icon={["fas", "search"]} size="sm" />
                        </i>
                        Búsqueda
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="btn-add-wrapper">
                    <Button
                      size="sm"
                      className="add-btn default-btn"
                      onClick={() => handleClickUpdate({})}
                    >
                      <i>
                        <FontAwesomeIcon
                          icon={["fas", "folder-plus"]}
                          size="sm"
                        />
                      </i>
                      Agregar
                    </Button>
                  </div>
                </Col>
              </Row>

              <div className="tab-table">
                <Row>
                  <Col>
                    <Table responsive striped="columns">
                      <thead>
                        <tr>
                          <th></th>
                          <th>#</th>
                          <th>UUID</th>
                          <th>TIPO</th>
                          <th>TEMPLATE</th>
                          <th>ESTUDIANTE</th>
                          <th>CURSO</th>
                          <th>GENERADO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.list.length > 0 &&
                          data.list.map((item, index) => (
                            <tr key={index}>
                              <td className="col-btns" align="center">
                                <div className="btn-wrapper">
                                  <Button
                                    className="edit-btn"
                                    onClick={() => handleClickUpdate(item)}
                                  >
                                    <i>
                                      <FontAwesomeIcon
                                        icon={["fas", "edit"]}
                                        size="sm"
                                        fixedWidth
                                      />
                                    </i>
                                  </Button>
                                  <Button className="delete-btn">
                                    <i>
                                      <FontAwesomeIcon
                                        icon={["fas", "trash-alt"]}
                                        size="sm"
                                        fixedWidth
                                      />
                                    </i>
                                  </Button>
                                </div>
                              </td>
                              <td>{(page - 1) * 25 + (index + 1)}</td>
                              <td>{item.uuid}</td>
                              <td>{item.certificate_type}</td>
                              <td>{item.certificate_template_id}</td>
                              <td>{item.student_name}</td>
                              <td>{item.course_name}</td>
                              <td>
                                {moment(item.print_date).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
              {data.totalPage > 1 && (
                <Row>
                  <Col>
                    <div className="pagination-wrapper">
                      <PaginationTable
                        total={data.totalPage}
                        current={page}
                        onChangePage={handleChangePage}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Tab>
            <Tab eventKey="import-and-export" title="Importa / Exportar">
              <div className="tab-import-and-export">
                <Row>
                  <Col>
                    <Formik
                      initialValues={{
                        file: [],
                      }}
                      validationSchema={Yup.object({
                        file: Yup.array()
                          .min(1, "Seleccione un archivo")
                          .max(3, "Solo se acepta un archivo"),
                      })}
                      onSubmit={async (values) => {
                        try {
                          setMessageFileUpload("");
                          let data = new FormData();
                          data.append("file", values.file[0]);
                          fileUploadTemplate.current.value = null;
                          values.file = [];
                          const response = await axios.post(
                            `${URL_API}/system/certificate/template`,
                            data,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                              responseType: "arraybuffer",
                            }
                          );
                          const blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          });
                          const url = window.URL.createObjectURL(blob);
                          let a = document.createElement("a");
                          a.href = url;
                          a.download = CERTIFICATE_UPLOAD_FILE_NAME;
                          a.click();
                        } catch (error) {
                          console.log("error", error);
                          if (error.response.data.error) {
                            if (error.response.data.error.status == 449) {
                              setMessageFileUpload(
                                JSON.stringify(
                                  JSON.parse(error.response.data.error.msg)
                                )
                              );
                            } else {
                              setMessageFileUpload(
                                error.response.data.error.msg
                              );
                            }
                          } else {
                            setMessageFileUpload(error.message);
                          }
                        }
                      }}
                    >
                      {({ handleSubmit, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={8}>
                              <FormBoot.Group controlId="file" className="">
                                <FormBoot.Label>
                                  Importar plantilla de carga de certificados
                                </FormBoot.Label>
                                <FormBoot.Control
                                  type="file"
                                  size="sm"
                                  name="file"
                                  ref={fileUploadTemplate}
                                  onChange={(event) => {
                                    const files = event.target.files;
                                    let myFiles = Array.from(files);
                                    setFieldValue("file", myFiles);
                                  }}
                                />
                              </FormBoot.Group>
                            </Col>
                            <Col
                              md={4}
                              xs={12}
                              className="import-export-btn-wrapper"
                            >
                              <div className="btn-add-wrapper">
                                <Button
                                  type="submit"
                                  className="default-btn import-btn"
                                >
                                  <i>
                                    <FontAwesomeIcon icon={["fas", "upload"]} />
                                  </i>{" "}
                                  <i>
                                    <FontAwesomeIcon
                                      icon={["fas", "file-excel"]}
                                    />
                                  </i>
                                  Subir plantilla
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ErrorMessage name="file" />
                            </Col>
                          </Row>
                          <Row>
                            {messageFileUpload === "" ? null : (
                              <p className="error-msg">{messageFileUpload}</p>
                            )}
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <CertificateModalForm
        data={certificate}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Layout>
  );
};

export default Certificate;
