import React, { useEffect } from "react";
import {
  Button,
  Modal,
  FloatingLabel,
  Form as FormBoot,
  Row,
  Col,
} from "react-bootstrap";
import * as moment from "moment";
import { Formik, Form } from "formik";

const CertificateModalForm = (props) => {
  console.log("props", props);
  useEffect(() => {
    console.log("consulte useEffect");
    // const fetchData = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${URL_API}/system/certificate/list?size=25&page=${page}`
    //     );
    //     const res = response.data;
    //     console.log("res", res);
    //     setData({
    //       list: res.data.list,
    //       totalPage: res.data.pagination.totalPage,
    //     });
    //   } catch (error) {
    //     console.log("error", error);
    //   }
    // };
    // fetchData();
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            ...props.data,
            course_score:
              !props.data.course_score || props.data.course_score == null
                ? 0
                : props.data.course_score,
            start_date: moment(props.data.start_date).format("YYYY-MM-DD"),
            end_date: moment(props.data.start_date).format("YYYY-MM-DD"),
          }}
          onSubmit={(values) => {
            console.log("values", values);
            console.log("Formulario Enviado");
          }}
        >
          {({ values, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="uuid"
                    label="Identificador único"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      type="text"
                      name="uuid"
                      value={values.uuid}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <FloatingLabel
                    controlId="certificate_type_id"
                    label="Tipo de certificado"
                    className="mb-3"
                  >
                    <FormBoot.Select
                      aria-label="Floating label select example"
                      value={values.certificate_type_id}
                      onChange={handleChange}
                    >
                      <option disabled={true}>Seleccione una opción</option>
                      <option value="1">CONSTANCIA</option>
                      <option value="2">CERTIFICADO</option>
                    </FormBoot.Select>
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="certificate_template_id"
                    label="Tipo de template"
                    className="mb-3"
                  >
                    <FormBoot.Select
                      aria-label="Floating label select example"
                      value={values.certificate_template_id}
                      onChange={handleChange}
                    >
                      <option disabled={true}>Seleccione una opción</option>
                      <option value="1">TIPO 1</option>
                      <option value="2">TIPO 2</option>
                      <option value="3">TIPO 3</option>
                      <option value="4">TIPO 4</option>
                      <option value="5">TIPO 5</option>
                    </FormBoot.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <FloatingLabel
                    controlId="person_identity_document_types_id"
                    label="Tipo de documento"
                    className="mb-3"
                  >
                    <FormBoot.Select
                      aria-label="Floating label select example"
                      value={values.person_identity_document_types_id}
                      onChange={handleChange}
                    >
                      <option disabled={true}>Seleccione una opción</option>
                      <option value="1">DNI</option>
                      <option value="2">CARNET DE EXTRANJERÍA</option>
                      <option value="3">PASAPORTE</option>
                      <option value="0">OTROS</option>
                    </FormBoot.Select>
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="person_document_code"
                    label="Número de documento"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      type="text"
                      name="person_document_code"
                      value={values.person_document_code}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="student_name"
                    label="Nombres y apellidos"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      type="text"
                      name="student_name"
                      value={values.student_name}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <FloatingLabel
                    controlId="course_hours"
                    label="Horas del curso"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      type="number"
                      min="0"
                      max="1000"
                      step="5"
                      pattern="^[0-9]+"
                      name="course_hours"
                      value={values.course_hours}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="course_score"
                    label="Calificación del curso"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      type="number"
                      min="0"
                      max="20"
                      step="0.5"
                      name="course_score"
                      value={values.course_score}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <FloatingLabel
                    controlId="start_date"
                    label="Fecha de inicio"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      type="date"
                      name="start_date"
                      value={values.start_date}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="end_date"
                    label="Fecha de fin"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      type="date"
                      name="end_date"
                      value={values.end_date}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="course_list"
                    label="Lista de Cursos (separar por | )"
                    className="mb-3"
                  >
                    <FormBoot.Control
                      as="textarea"
                      style={{ height: "100px" }}
                      value={values.course_list}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit"> Enviar</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CertificateModalForm;
