import { useState } from "react";

const initialState = {
    navToggleActive: false,
};

const useInitialState = () => {
    const [stateApp, setStateApp] = useState(initialState);

    const navToggle = (isActive) => {
        setStateApp({
            ...stateApp,
            navToggleActive: isActive
        });
    }
    const navConsole= ()=>{
        console.log('navConsole', navConsole)
    }

    return {
        stateApp,
        navToggle,
        navConsole
    }
}

export default useInitialState;

