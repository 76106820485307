import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import axios from "axios";
import * as moment from "moment";

import { TAG_TOKEN, URL_API } from "@configs/consts";

const initialState = {
  token: window.localStorage.getItem(TAG_TOKEN),
  user: "",
  userLogged: false,
  authStatus: "",
  authErrorMsg: "",
  isLogging: false,
};

/** Thunks */
export const authUser = createAsyncThunk(
  "auth/authUser",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL_API}/system/auth`, {
        username: values.username,
        password: values.password,
      });
      const res = response.data;
      localStorage.setItem(TAG_TOKEN, res.data.accessToken);
      return res;
    } catch (error) {
      const err = error.response.data
        ? error.response.data.error.msg
        : error.message;
      return rejectWithValue(err);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadUser(state, action) {
      const token = state.token;
      if (token) {
        const jwtObj = jwtDecode(token);
        const expJwt = moment.unix(jwtObj.exp);
        const actualDate = moment();
        const diff = expJwt.diff(actualDate, "hours");
        if (diff > 0) {
          return {
            ...state,
            token: token,
            user: jwtObj.user.name,
            userLogged: true,
          };
        } else {
          window.localStorage.removeItem(TAG_TOKEN);
          return {
            ...state,
            token: "",
            user: "",
            userLogged: false,
            msgError: "",
          };
        }
      } else {
        return {
          ...state,
          userLogged: false,
        };
      }
    },
    logoutUser(state, action) {
      window.localStorage.removeItem(TAG_TOKEN);
      return {
        ...state,
        token: "",
        user: "",
        userLogged: false,
        msgError: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.pending, (state, action) => {
      return { ...state, isLogging: true };
    });
    builder.addCase(authUser.fulfilled, (state, action) => {
      if (action.payload) {
        const jwtObj = jwtDecode(action.payload.data.accessToken);
        return {
          ...state,
          token: action.payload.data.accessToken,
          user: jwtObj.user.name,
          userLogged: true,
          isLogging: false,
        };
      } else return state;
    });
    builder.addCase(authUser.rejected, (state, action) => {
      return {
        ...state,
        authStatus: "rejected",
        authErrorMsg: action.payload,
        isLogging: false,
      };
    });
  },
});

export const { loadUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;
