import React from "react";
import { connect } from "react-redux";;
import Layout from "@containers/Layout";

const Home = ({auth}) => {
    console.log('auth111', auth)
  return (
    <Layout>
      <h1>Grupo Iddea Home</h1>
    </Layout>
  );
};

const stateProps = store =>{
    return {
        auth: store.auth
    }
}

export default connect(stateProps,null)(Home);
// export default Home;
