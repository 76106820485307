import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: null,
  reducers: {
    createUser: (state, action) => action.payload,
    resetUser: () => null,
  },
});

//Acciones
export const { createUser, resetUser } = userSlice.actions;
export default userSlice.reducer;
