import React from 'react';
const NotFound = () =>{
    return(
        <div>
            <h1>No se encontro la pagina</h1>
        </div>
    );
}

export default NotFound;
