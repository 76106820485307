import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {useDispatch} from 'react-redux';
import { loadUser } from "@store/slices";

/** Styles */
import 'bootstrap/dist/css/bootstrap.min.css';
import "@styles/Main.scss";

/** Pages */
import Home from "@pages/Home";
import Login from "@pages/Login";
import Certificate from "@pages/Certificate";
import NotFound from "@pages/NotFound";
import ProtectedRoute from "@containers/ProtectedRoute";

/** Context */
import AppContext from "@contexts/AppContext";
import useInitialState from "@hooks/useInitialState";


/** Configs FontAwasome */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, fas);

const App = () => {
  const initialState = useInitialState();
  const dispatch = useDispatch();
  dispatch(
    loadUser(null)
  );
  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/certificate"
            element={
              <ProtectedRoute>
                <Certificate />
              </ProtectedRoute>
            }
          />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
