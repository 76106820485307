import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import AppContext from "@contexts/AppContext.js";
import { logoutUser } from "@store/slices";

import SidebarItem from "@components/SidebarItem";

import "@styles/Sidebar.scss";

const Sidebar = () => {
  const { stateApp } = useContext(AppContext);

  const dispatch = useDispatch();
  const history = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  const handleCertificate = (e) => {
    e.preventDefault();
    history('/certificate');
  };
  return (
    <div
      id="Sidebar"
      className={stateApp.navToggleActive ? "sidebar" : "sidebar active"}
    >
      <div className="sidebar-wrapper">
        <ul>
          <SidebarItem iconStyle="fab" iconName="apple" title="Grupo IDDEA" />
          <SidebarItem iconStyle="fas" iconName="home" title="Dashboard" />
          <SidebarItem
            iconStyle="fas"
            iconName="user-friends"
            title="Customers"
          />
          <SidebarItem iconStyle="fas" iconName="comment" title="Message" />
          <SidebarItem
            iconStyle="fas"
            iconName="user-graduate"
            title="Certificados"
            handle={handleCertificate}
          />
          <SidebarItem iconStyle="fas" iconName="cog" title="Settings" />
          <SidebarItem iconStyle="fas" iconName="lock" title="Password" />
          <SidebarItem
            iconStyle="fas"
            iconName="sign-in-alt"
            title="Cerrar Sesión"
            handle={handleLogout}
          />
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
