import React from 'react';

import "@styles/WorkArea.scss";
const WorkArea = ({ children }) => {
    return (
        <div id="WorkArea" className='container work-area'>
            {children}
        </div>
    );
};

export default WorkArea;