import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import useLoginState from "@hooks/useLoginState.js";
import { authUser } from "@store/slices";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "@styles/Login.scss";

import logo from "@images/logo-iddea.png";

const Login = () => {
  const { login, setLoginForm } = useLoginState();
  const dispatch = useDispatch();
  const history = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(authUser(login));
    history("/");
  };
  const auth = useSelector((store) => store.auth);
  useEffect(() => {
    if (auth.token) {
      history("/");
    }
  }, [auth.token, history]);
  return (
    <section id="LoginPage" className="login-page">
      <div className="login-page-wrapper">
        <div className="login-wrapper">
          <img className="login-logo" src={logo} />
          <p className="login-title">Grupo IDDEA - Administración</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="username">Usuario</label>
              <input
                type="text"
                name="username"
                className="username"
                pattern="[A-Za-z0-9_-]{5,15}"
                onChange={setLoginForm}
                required
              />
            </div>
            <div className="input">
              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                className="password"
                name="password"
                pattern="[A-Za-z0-9_-@]{6,15}"
                onChange={setLoginForm}
                required
              />
            </div>
            <div className="button">
              <button type="submit" disabled={auth.isLogging ? true : false}>
                Iniciar Sesión
                <i>
                  <FontAwesomeIcon icon={["fas", "paper-plane"]} />
                </i>
              </button>
            </div>
          </form>
          {auth.authStatus === "rejected" ? (
            <p className="error-msg">{auth.authErrorMsg}</p>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Login;
